import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useMemo } from 'react';
import { numberFormatter, priceFormatter } from '../helpers/formatters';
import { ReduxPointSearch } from '../search/ReduxPointSearch';
import { EditableTable } from '../helpers/EditableTable';
import { scenarioActions, selectTrades } from '../scenarios/scenarioSlice';
import { MarketPrice } from '../markets/MarketPrice';
import { colors } from '../utils/colors';
import * as plotterSelectors from '../plotter/plotterSelectors';
import { ZoomInIcon } from '@iconicicons/react';
import { usePanMap } from '../plotter/usePanMap';
import { DateSelector } from '../helpers/DateSelector';
import { toIsoDate } from '../utils/stringUtils';
import { useLazyGetBaseloadQuery } from '../services/edgeApi';
import { SpreadsheetModal } from '../helpers/SpreadsheetModal';
import { useDebouncedCallback } from '../helpers/hooks';

/**
 * @typedef {{
 *  POINT: 0,
 *  LOC_NAME: 1,
 *  DESCRIPTION: 2,
 *  BUY_SELL: 3,
 *  VOLUME: 4,
 *  PRICE: 5,
 * }} TradeSpreadsheetColumns
 */

/**
 *
 * Spreadsheet column indexers
 *
 * @type {TradeSpreadsheetColumns}
 */
const COLUMNS = {
  POINT: 0,
  LOC_NAME: 1,
  DESCRIPTION: 2,
  BUY_SELL: 3,
  VOLUME: 4,
  PRICE: 5,
};

const REQUIRED_COLUMNS = [
  COLUMNS.POINT,
  COLUMNS.BUY_SELL,
  COLUMNS.VOLUME,
  COLUMNS.PRICE,
];

/**
 * @type {Record<TradeSpreadsheetColumns, string>}
 */
const COLUMNS_LABELS = {
  [COLUMNS.POINT]: 'Point',
  [COLUMNS.LOC_NAME]: 'Location',
  [COLUMNS.DESCRIPTION]: 'Description',
  [COLUMNS.BUY_SELL]: 'Buy/Sell',
  [COLUMNS.VOLUME]: 'Volume',
  [COLUMNS.PRICE]: 'Market Price',
};

/**
 *
 * @param {{
 *  preSelect?: import('../scenarios/scenarioSlice').Trade[]
 * }} props
 * @returns
 */
export function Trades(props) {
  const { preSelect } = props;
  const componentType = 'trades';

  const [baseloadTrigger, { isFetching: fetchingBaseload }] =
    useLazyGetBaseloadQuery();

  // set trades in redux that are preselected on first render
  useEffect(() => {
    if (preSelect) {
      dispatch(
        scenarioActions.overwrite({ componentType, components: preSelect }),
      );
    }
  }, [preSelect]);

  const _trades = useSelector(selectTrades);

  const priceSource = useSelector(
    (state) => state.scenario.data.settings.price_source,
  );

  /** @type {boolean} */
  const isEditing = useSelector((state) => state.scenario.isEditing);

  const allNodes = useSelector(plotterSelectors.selectAllNodes);
  const allPoints = useSelector(plotterSelectors.selectAllPoints);

  const dispatch = useDispatch();
  const panMap = usePanMap();

  const trades = useMemo(
    () =>
      _trades.map((trade) => {
        const point = allPoints[trade.point];
        return {
          ...trade,
          pipeline: point?.tsp_name ?? '',
        };
      }),
    [_trades],
  );

  /** @type {[boolean, React.SetStateAction<boolean>]} */
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [gasDate, setGasDate] = useState(new Date());

  /**
   * @type {(data: import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>) => void}
   */
  const onChangeSpreadsheetDebounce = useDebouncedCallback(
    (data) => {
      const newData = data.map((row) => {
        let newRow = [...row];
        if (
          row[COLUMNS.POINT].value !== '' &&
          !isNaN(Number(row[COLUMNS.POINT].value))
        ) {
          newRow[COLUMNS.LOC_NAME].value =
            allPoints[row[COLUMNS.POINT].value]?.name ?? '';
        }
        return newRow;
      });
      sheetRef.current?.setData(newData);
    },
    1000,
    [allPoints],
  );

  /** @type {React.Ref<import('../helpers/Spreadsheet').SpreadsheetRef<import('react-spreadsheet').CellBase>>} */
  const sheetRef = React.useRef(null);

  const volumeDifference = useMemo(() => {
    return trades.reduce((acc, trade) => {
      if (trade.buy_sell === 'BUY') {
        return acc + Number(trade.volume);
      }
      if (trade.buy_sell === 'SELL') {
        return acc - Number(trade.volume);
      }
      return acc;
    }, 0);
  }, [trades]);

  const saveBulkEdit = (selection, updateData) => {
    dispatch(
      scenarioActions.bulkUpdate({
        componentType,
        componentIds: selection,
        data: updateData,
      }),
    );
  };

  const saveItem = (original, edited) => {
    dispatch(
      scenarioActions.updateComponent({
        componentType,
        component: edited,
      }),
    );
    if (!original || !original.id) {
      setIsAdding(false);
    }
  };

  const removeItem = (id) => {
    dispatch(
      scenarioActions.removeComponent({ componentType, componentId: id }),
    );
  };

  const bulkRemove = (ids) => {
    dispatch(scenarioActions.bulkDelete({ componentType, componentIds: ids }));
  };

  /**
   *
   * @param {import('react-spreadsheet').Matrix<import('react-spreadsheet').CellBase>} data
   */
  const confirmImport = (data) => {
    setImportModalOpen(false);
    /** @type {import('../scenarios/scenarioSlice').Trade[]} */
    const trades = data
      .filter((row) => {
        return REQUIRED_COLUMNS.every((col) => row[col].value !== '');
      })
      .map((row) => {
        const price = parseFloat(row[COLUMNS.PRICE].value);
        return {
          point: parseInt(row[COLUMNS.POINT].value),
          description: row[COLUMNS.DESCRIPTION].value,
          buy_sell: String(row[COLUMNS.BUY_SELL].value).toUpperCase(),
          price,
          volume: parseFloat(row[COLUMNS.VOLUME].value),
          base_price: price,
          adder: 0,
        };
      });
    dispatch(
      scenarioActions.appendComponents({
        componentType,
        components: trades,
      }),
    );
  };

  const overwriteBaseload = async () => {
    try {
      const result = await baseloadTrigger({
        gasDate: toIsoDate(gasDate),
        priceSource: priceSource,
      }).unwrap();
      dispatch(
        scenarioActions.overwrite({
          componentType: 'trades',
          components: Object.values(result),
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const renderPointInput = ({
    value: currentPoint,
    dirty,
    saved,
    onChange,
  }) => {
    const style = {};
    style.backgroundColor = dirty ? '#F9FCDC' : '';
    style.backgroundColor = saved ? '#E7FFDE' : style.backgroundColor;
    return (
      <ReduxPointSearch
        style={style}
        className="form-control-sm"
        handler={(point) => onChange(point.id)}
        value={currentPoint}
        placeholder={currentPoint || 'Point #'}
        buttonTitle="Select A Point"
      />
    );
  };

  return (
    <>
      <div className="row justify-content-between m-3">
        <div className="col-6 col-md-8 col-sm-12">
          <DateSelector
            value={toIsoDate(gasDate)}
            onChange={(evt) => setGasDate(evt.target.valueAsDate)}
            isLoading={fetchingBaseload}
            onConfirm={overwriteBaseload}
            buttonText="Get Baseload"
          />
        </div>
        <div className="col-auto">
          {isEditing && (
            <>
              <button
                onClick={() => setImportModalOpen(true)}
                className="btn btn-primary me-2"
              >
                Spreadsheet View
              </button>
              <button
                onClick={() => setIsAdding((prev) => !prev)}
                className="btn btn-success"
              >
                {isAdding ? 'Cancel' : 'Add Trade'}
              </button>
            </>
          )}
        </div>
      </div>
      <div className="row justify-content-between m-3">
        <div className="col-auto">
          {Math.abs(volumeDifference) > 0 && (
            <p>
              Balance:{' '}
              <strong
                style={{
                  color:
                    volumeDifference > 0 ? colors.matteGreen : colors.matteRed,
                }}
              >
                {numberFormatter(Math.abs(volumeDifference))} dth{' '}
                {volumeDifference > 0 ? 'long' : 'short'}
              </strong>
            </p>
          )}
        </div>
      </div>
      <EditableTable
        columns={[
          {
            title: 'Point',
            key: 'point',
            type: 'text',
            placeholder: 'Point',
            default: '',
            isEditable: false,
            informedOnCreation: true,
            renderInput: renderPointInput,
            allowFiltering: true,
            displayFunction: (value) => {
              const pointName = allPoints[value]?.name;
              return `#${value} ${pointName}`;
            },
            renderCell: ({ data }) => {
              const pointName =
                Object.keys(allPoints).length > 0
                  ? allPoints[data.point]?.name ?? '--'
                  : 'Points Loading...';
              const node = allNodes[allPoints[data.point]?.node];

              return (
                <div
                  style={{
                    maxWidth: '350px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  <button
                    className="btn btn-light me-2"
                    disabled={!node}
                    onClick={() => {
                      panMap(node, 12);
                    }}
                  >
                    <ZoomInIcon color={node ? 'black' : 'grey'} />
                  </button>
                  <strong className="me-2">#{data.point}</strong> {pointName}
                </div>
              );
            },
          },
          {
            title: 'TSP',
            allowFiltering: true,
            key: 'pipeline',
            type: 'text',
            placeholder: 'TSP',
            isEditable: false,
            default: '',
            width: 0.06,
          },
          {
            title: 'Description',
            key: 'description',
            type: 'text',
            placeholder: 'Description',
            default: '',
            allowFiltering: true,
          },
          {
            title: 'Buy/Sell',
            key: 'buy_sell',
            type: 'select',
            placeholder: 'Select Type',
            allowFiltering: true,
            selectOptions: [
              { value: 'BOTH', label: 'Both' },
              { value: 'BUY', label: 'Buy' },
              { value: 'SELL', label: 'Sell' },
            ],
          },
          {
            title: 'Volume',
            key: 'volume',
            type: isEditing ? 'number' : 'text',
            placeholder: 'Volume',
            default: 0,
            displayFunction: numberFormatter,
          },
          {
            title: 'Trade Price',
            key: 'price',
            placeholder: 'Trade Price',
            type: 'number',
            default: 0,
            isEditable: true,
            displayFunction: (value) => {
              return priceFormatter.format(value);
            },
            renderInput: (renderProps) => {
              return <MarketPrice {...renderProps} />;
            },
          },
          {
            title: 'Price Explanation',
            key: 'price_explanation',
            type: 'text',
            isEditable: false,
          },
        ]}
        data={trades}
        keyExtractor={(item) => item.id}
        isEditing={isEditing}
        isEditable={isEditing}
        isAdding={isAdding}
        onBulkRemove={bulkRemove}
        onSaveBulkEdit={saveBulkEdit}
        onSaveItem={saveItem}
        onDeleteItem={removeItem}
        onCancelCreation={() => setIsAdding(false)}
        emptyText="No Trades"
      />
      <SpreadsheetModal
        isOpen={importModalOpen}
        toggle={() => setImportModalOpen((prev) => !prev)}
        title="Import Trades"
        subTitle={<p className="text-muted">Required fields: *</p>}
        ref={sheetRef}
        data={[]}
        readonlyColumns={[COLUMNS.LOC_NAME]}
        sheetProps={{
          onChange: onChangeSpreadsheetDebounce,
          columnLabels: Object.keys(COLUMNS_LABELS).map((col) => {
            return `${COLUMNS_LABELS[col]}${
              REQUIRED_COLUMNS.includes(Number(col)) ? ' *' : ''
            }`;
          }),
        }}
        fileName="test.xlsx"
        sheetName="test"
      >
        <button
          className="btn btn-primary"
          onClick={() => {
            confirmImport(sheetRef.current.getData());
            sheetRef.current.clear();
          }}
        >
          Confirm Import
        </button>
      </SpreadsheetModal>
    </>
  );
}
