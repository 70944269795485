import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  selectAllPoints,
  selectFilteredPoints,
} from '../plotter/plotterSelectors';

const initialState = {
  isSearching: false,
  searchQuery: null,
  favorites: [],
};

// TODO: we need to add filtering.  Search is really just selecting one thing from a filter.  Should they be combined or have a separate filterSlice?
export const searchSlice = createSlice({
  name: 'search',
  initialState,

  reducers: {
    searchingChanged: (state, action) => {
      // wow, look at this destructuring assignment! #superdev
      const { isSearching, itemType, selectionKey } = action.payload;
      if (isSearching) {
        return {
          ...state,
          isSearching,
          itemType,
          selectionKey,
        };
      }
      // just reset state between searches for now
      return initialState;
    },
    queryChanged: (state, action) =>
      // this could potentially go in selectedItemsSlice but it's very path specific so i'm putting it here for now
      ({ ...state, searchQuery: action.payload.searchQuery }),
  },
});

// TODO: need to create an asyncronous search so it doesn't lag
// export const filterSearchResults = createAsyncThunk(
//   'search/filterSearchResults',

// )
// TODO: search favorites, filter favorites - they should probably be saved and retrieved from the backend on a per user basis

// TODO: this should select points / segments / edges, whatever.  Just starting with points for now
// also now that there's a filters slice, this selector has a confusing name
export const selectFilteredPointIds = createSelector(
  selectAllPoints,
  selectFilteredPoints,
  (state) => state.search.searchQuery?.toLowerCase(),
  (allPoints, points, searchQuery) => {
    searchQuery = searchQuery === '' ? null : searchQuery;
    const allResults = Object.keys(points).filter((pointId) => {
      // console.log("ALL POINTS" + JSON.stringify(allPoints))
      // console.log("FILTERED POINTS" + JSON.stringify(points)

      const pointInfo = points[pointId];

      // PL-1404: Added name to Search to account for alias being different from loc_name.

      return (
        searchQuery === pointId ||
        searchQuery === `#${pointId}` ||
        pointInfo?.loc_name?.toLowerCase().includes(searchQuery) ||
        pointInfo?.name?.toLowerCase().includes(searchQuery) ||
        pointInfo?.loc_id?.includes(searchQuery) ||
        pointInfo?.seg_nbr?.includes(searchQuery) ||
        pointInfo?.loc_cnty?.includes(searchQuery) ||
        pointInfo?.capacity_components?.some((cc) =>
          cc?.description?.toLowerCase().includes(searchQuery),
        )
      );
    });
    // this makes search wayyy faster - just return the first x number of results
    return allResults.slice(0, 10);
  },
);

export const { searchingChanged, queryChanged } = searchSlice.actions;

export default searchSlice.reducer;
