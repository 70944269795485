import { useEffect, useMemo, useState } from 'react';
import {
  useGetTSPsQuery,
  useGetZonesQuery,
  useListIOCContractsQuery,
} from '../services/edgeApi';
import { BelowMapSetter } from '../layouts/BelowMapContext';
import { BelowMapTab } from '../layouts/BelowMapTab';
import { LoadingIndicator } from '../helpers/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import {
  iocActions,
  selectFilteredIOCContracts,
  selectIOCFilters,
  selectIOCVisibility,
} from './iocSlice';
import { MultiSelectSearch } from '../helpers/MultiSelect';
import { FormGroup, Label, Input } from 'reactstrap';
import { DateSelector } from '../helpers/DateSelector';
import { toIsoDate } from '../utils/stringUtils';
import { EyeIcon, EyeOffIcon } from '@iconicicons/react';
import { PointBadge } from '../helpers/PointBadge';
import { useClientPagination, useTheme, useTspFilter } from '../helpers/hooks';
import { selectAllPoints } from '../plotter/plotterSelectors';
import { numberFormatter } from '../helpers/formatters';
import { InfiniteScrollObserver } from '../helpers/InfiniteScrollObserver';

export const IOC = () => {
  const dispatch = useDispatch();
  const setTspFilter = useTspFilter();
  const [theme] = useTheme();
  const { data: tsps } = useGetTSPsQuery();
  const { data: zones } = useGetZonesQuery();

  const allPoints = useSelector(selectAllPoints);
  const visibility = useSelector(selectIOCVisibility);
  const filters = useSelector(selectIOCFilters);
  const filteredIocContracts = useSelector(selectFilteredIOCContracts);
  const {
    slice: paginatedIocContracts,
    exhausted,
    getNext,
  } = useClientPagination(filteredIocContracts, 30);

  const [filterTsps, setFilterTsps] = useState(true);
  const [gasDate, setGasDate] = useState(new Date());
  const {
    data: iocContracts,
    isLoading,
    isFetching,
  } = useListIOCContractsQuery(toIsoDate(gasDate));

  /**
   *
   * @param {keyof import('./iocSlice').IOCContractsFilter} key
   * @param {string} value
   */
  const setFilter = (key, value) => {
    dispatch(iocActions.setFilter({ key, value }));
  };

  const sortedTsps = useMemo(
    () =>
      Object.values(tsps ?? {}).sort((a, b) => a.name.localeCompare(b.name)),
    [tsps],
  );

  useEffect(() => {
    return () => {
      dispatch(iocActions.resetState());
    };
  }, []);

  useEffect(() => {
    dispatch(iocActions.setContracts(iocContracts ?? []));
  }, [iocContracts]);

  useEffect(() => {
    if (filterTsps) {
      const highlightedTsps = new Set(
        filteredIocContracts.map((contract) => contract.tsp.name),
      );
      setTspFilter(
        highlightedTsps.length === sortedTsps.length
          ? []
          : Array.from(highlightedTsps),
      );
    }
  }, [filteredIocContracts, sortedTsps, filterTsps]);

  /**
   *
   * @param {import('./iocSlice').IOCContract[]} contracts
   * @param {boolean} visible
   */
  const setVisibility = (contracts, visible) => {
    dispatch(iocActions.setVisibility({ contracts, visible }));
  };

  const uniqueShippers = useMemo(() => {
    return [
      ...new Set(
        iocContracts?.map((contract) =>
          contract.owner
            ?.toUpperCase()
            .replace(/[^A-Z0-9 ]/g, '')
            .replace(/ +/g, ' '),
        ) ?? [],
      ),
    ].sort();
  }, [iocContracts]);

  const uniqueAgentNames = useMemo(() => {
    return [
      ...new Set(
        iocContracts?.map((contract) =>
          contract.agent_name
            ?.toUpperCase()
            .replace(/[^A-Z0-9 ]/g, '')
            .replace(/ +/g, ' '),
        ) ?? [],
      ),
    ].sort();
  }, [iocContracts]);

  const uniqueRateSchedules = useMemo(() => {
    return [
      ...new Set(
        iocContracts?.map((contract) => contract.raw_rate_schedule) ?? [],
      ),
    ].sort();
  }, [iocContracts]);

  const filteredZones = useMemo(() => {
    return Object.values(zones ?? {})
      .filter((zone) => filters.tsp.includes(zone.tsp.id))
      .map((zone) => ({
        name: `${zone.tsp.short_name || zone.tsp.long_name}: ${zone.name}`,
        id: zone.id,
      }));
  }, [zones, filters.tsp]);

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <h2>Index of Customers</h2>
        <DateSelector
          value={toIsoDate(gasDate)}
          onChange={(evt) => setGasDate(evt.target.valueAsDate)}
          label="Gas Date"
        />
        <h3>Filters</h3>
        <div>
          <div className="form-group">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="filter-tsp-checkbox"
                checked={filterTsps}
                onChange={() => setFilterTsps(!filterTsps)}
              />
              <Label className="form-check-label">
                Show highlighted TSPs Only
              </Label>
            </div>
          </div>
          <FormGroup>
            <Label>Owner</Label>
            <MultiSelectSearch
              onChange={(value) =>
                setFilter(
                  'owner',
                  value.map((v) => v.value),
                )
              }
              options={uniqueShippers.map((shipper) => ({
                value: shipper,
                label: shipper,
              }))}
            />
          </FormGroup>
          <FormGroup>
            <Label>Agent Name</Label>
            <MultiSelectSearch
              onChange={(value) =>
                setFilter(
                  'agent_name',
                  value.map((v) => v.value),
                )
              }
              options={uniqueAgentNames.map((agentName) => ({
                value: agentName,
                label: agentName,
              }))}
            />
          </FormGroup>
          <div className="row">
            <div className="col-6">
              <FormGroup>
                <Label>TSP</Label>
                <MultiSelectSearch
                  onChange={(value) =>
                    setFilter(
                      'tsp',
                      value.map((v) => v.value),
                    )
                  }
                  options={sortedTsps.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                />
              </FormGroup>
            </div>
            <div className="col-6">
              <FormGroup>
                <Label>Rate Schedule</Label>
                <MultiSelectSearch
                  onChange={(value) =>
                    setFilter(
                      'raw_rate_schedule',
                      value.map((v) => v.value),
                    )
                  }
                  options={uniqueRateSchedules.map((rateSchedule) => ({
                    value: rateSchedule,
                    label: rateSchedule,
                  }))}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <FormGroup>
                <Label>Receipt Zone</Label>
                <MultiSelectSearch
                  onChange={(value) =>
                    setFilter(
                      'receipt_zones',
                      value.map((v) => v.value),
                    )
                  }
                  options={filteredZones.map((zone) => ({
                    value: zone.id,
                    label: zone.name,
                  }))}
                />
              </FormGroup>
            </div>
            <div className="col-6">
              <FormGroup>
                <Label>Delivery Zone</Label>
                <MultiSelectSearch
                  onChange={(value) =>
                    setFilter(
                      'delivery_zones',
                      value.map((v) => v.value),
                    )
                  }
                  options={filteredZones.map((zone) => ({
                    value: zone.id,
                    label: zone.name,
                  }))}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <FormGroup>
                <Label>Receipt Point</Label>
                <Input
                  type="text"
                  onChange={(e) => setFilter('receipt_points', e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-6">
              <FormGroup>
                <Label>Delivery Point</Label>
                <Input
                  type="text"
                  onChange={(e) => setFilter('delivery_points', e.target.value)}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      <BelowMapSetter>
        <BelowMapTab tabName="Index of Customers" contextKey="ioc">
          <div>
            <table className="table">
              <thead
              // style={{
              //   position: 'sticky',
              //   top: 0,
              // }}
              >
                <tr>
                  <th>
                    {filteredIocContracts.some(
                      (contract) => visibility[contract.id],
                    ) ? (
                      <EyeIcon
                        role="button"
                        onClick={() =>
                          setVisibility(filteredIocContracts, false)
                        }
                      />
                    ) : (
                      <EyeOffIcon
                        role="button"
                        onClick={() =>
                          setVisibility(filteredIocContracts, true)
                        }
                      />
                    )}
                  </th>
                  <th>Contract Number</th>
                  <th>Shipper Name</th>
                  <th>Agent Name</th>
                  <th>TSP Name</th>
                  <th>Receipt Points</th>
                  <th>Delivery Points</th>
                  <th>Rate Schedule</th>
                  <th>Max TRP</th>
                  <th>Contract Start</th>
                  <th>Contract End</th>
                </tr>
              </thead>
              <tbody>
                {isLoading || isFetching ? (
                  <tr>
                    <td colSpan="11" className="text-center">
                      <LoadingIndicator />
                    </td>
                  </tr>
                ) : paginatedIocContracts?.length > 0 ? (
                  paginatedIocContracts.map((contract) => {
                    return (
                      <tr key={contract.id.toString()}>
                        <td className="text-center">
                          {visibility[contract.id] ? (
                            <EyeIcon
                              role="button"
                              onClick={() => setVisibility([contract], false)}
                            />
                          ) : (
                            <EyeOffIcon
                              role="button"
                              onClick={() => setVisibility([contract], true)}
                            />
                          )}
                        </td>
                        <td>{contract.k_id}</td>
                        <td>{contract.owner}</td>
                        <td>{contract.agent_name}</td>
                        <td>{contract.tsp.name}</td>
                        <td>
                          {contract.receipt_points.map((pointId) => (
                            <PointBadge
                              point={allPoints[pointId]}
                              key={pointId.toString()}
                              theme={theme}
                            />
                          ))}
                        </td>
                        <td>
                          {contract.delivery_points.map((pointId) => (
                            <PointBadge
                              point={allPoints[pointId]}
                              key={pointId.toString()}
                              theme={theme}
                            />
                          ))}
                        </td>
                        <td>{contract.raw_rate_schedule}</td>
                        <td>
                          {numberFormatter(contract.max_daily_quantity, 0)}
                        </td>
                        <td>
                          {new Date(contract.start_date).toLocaleDateString()}
                        </td>
                        <td>
                          {new Date(contract.end_date).toLocaleDateString()}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="11" className="text-center">
                      No records found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {!(isLoading || isFetching) &&
              paginatedIocContracts?.length > 0 &&
              !exhausted && <InfiniteScrollObserver onScrollIn={getNext} />}
          </div>
        </BelowMapTab>
      </BelowMapSetter>
    </>
  );
};
