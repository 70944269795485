import { Polyline, Tooltip } from 'react-leaflet';
import { useSelector } from 'react-redux';
import {
  useGetInterconnectsQuery,
  useGetTSPsQuery,
  // useDisableInterconnectMutation,
  // useEnableInterconnectMutation,
} from '../services/edgeApi';
import * as plotterSelectors from '../plotter/plotterSelectors';
import { useMemo } from 'react';
// import { useEffect, useState } from 'react';

export function LeafletMapInterconnects() {
  const { data: interconnects } = useGetInterconnectsQuery();
  // const [interconnectStatus, setInterconnectStatus] = useState({});
  const points = useSelector(plotterSelectors.selectFilteredPoints);
  const nodes = useSelector(plotterSelectors.selectAllNodes);
  const allPoints = useSelector(plotterSelectors.selectAllPoints);
  const uniqueTspList = useMemo(
    () => [...new Set(Object.values(points).map((item) => item.tsp))],
    [points],
  );
  // const [triggerDisable, disableMutationMeta] =
  //   useDisableInterconnectMutation();
  // const [triggerEnable, enableMutationMeta] = useEnableInterconnectMutation();
  const { data: tsps } = useGetTSPsQuery();

  /** I'm doing this because "interconnects" is readonly
   *  and I need to be able to override the value of enabled whenever it changes.
   *  One other possibility would be to refetch all the interconnects.
   *
   *  UPDATE: We decided not to have this ability for now, I'm leaving this here
   *  in case we change our minds.
   */
  // useEffect(() => {
  //   if (!interconnects) return;
  //   setInterconnectStatus(
  //     Object.keys(interconnects).reduce((acc, id) => {
  //       acc[id] = interconnects[id].enabled;
  //       return acc;
  //     }, {}),
  //   );
  // }, [interconnects]);

  // const toggleInterconnectStatus = async (id, checked) => {
  //   const trigger = checked ? triggerEnable : triggerDisable;
  //   const response = await trigger({ id });
  //   if (response.error) {
  //     console.error('Error updating interconnect state', id);
  //     return;
  //   }
  //   setInterconnectStatus({
  //     ...interconnectStatus,
  //     [id]: response.data.enabled,
  //   });
  // };

  if (!interconnects) {
    return null;
  }

  const pathStyle = {
    [true]: {
      dashArray: '10, 0',
      dashOffset: '0',
    },
    [false]: {
      dashArray: '10, 5',
      dashOffset: '8',
    },
  };

  return (
    // need to loop through and calculate position based on start point node / end point node.  Or send it from the backend?
    Object.keys(interconnects).map((interconnectId) => {
      const ic = interconnects[interconnectId];
      const start_point = allPoints[ic.start_point];
      const start_node = nodes[start_point?.node];
      const end_point = allPoints[ic.end_point];
      const end_node = nodes[end_point?.node];
      if (!start_node || !start_point || !end_node || !end_point) return null;
      // const enabled = interconnectStatus[interconnectId];
      const enabled = ic.enabled;

      const positions = [
        [start_node?.lat, start_node?.lng],
        [end_node?.lat, end_node?.lng],
      ];
      if (
        start_node?.lat &&
        start_node?.lng &&
        end_node?.lat &&
        end_node?.lng &&
        (uniqueTspList.includes(start_point?.tsp) ||
          uniqueTspList.includes(end_point?.tsp))
      ) {
        return (
          <Polyline
            key={interconnectId}
            positions={positions}
            pathOptions={{
              // color: enabled ? '#00BFFF' : '#7ec0d8',
              color: '#00BFFF',
              opacity: enabled ? 1 : 0.4,
              dashArray: pathStyle[enabled].dashArray,
              dashOffset: pathStyle[enabled].dashOffset,
            }}
            pane="edgesPane"
          >
            {/* <Popup keepInView={false} closeButton={false} autoPan={false}>
              <div
                style={{ width: '200px' }}
                className="justify-content-center"
              >
                <h4>Interconnect #{interconnectId}</h4>
                <p>
                  From {tsps?.[start_point.tsp]?.name} to{' '}
                  {tsps?.[end_point.tsp]?.name}
                </p>
                <div className="form-check form-switch" role="button">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="interconnect-status-input"
                    checked={enabled}
                    disabled={
                      enableMutationMeta.isLoading ||
                      disableMutationMeta.isLoading
                    }
                    onChange={(e) =>
                      toggleInterconnectStatus(interconnectId, e.target.checked)
                    }
                    role="button"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="interconnect-status-input"
                    role="button"
                    style={{
                      color: enabled ? 'limegreen' : 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    {enabled ? 'Enabled' : 'Disabled'}
                  </label>
                </div>
              </div>
            </Popup> */}
            <Tooltip opacity={1} pane="tooltipsPane">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="row">Interconnect</th>
                    <td colSpan={2}>#{interconnectId}</td>
                  </tr>
                  <tr>
                    <th scope="row">Status</th>
                    <td
                      style={{
                        color: enabled ? 'limegreen' : 'red',
                        fontWeight: 'bold',
                      }}
                      colSpan={2}
                    >
                      {enabled ? 'Enabled' : 'Disabled'}
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <th>From</th>
                    <th>To</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">TSP</th>
                    <td>{tsps?.[start_point.tsp]?.name}</td>
                    <td>{tsps?.[end_point.tsp]?.name}</td>
                  </tr>
                  <tr>
                    <th scope="row">Loc Name</th>
                    <td>{`#${start_point.id} ${start_point.name}`}</td>
                    <td>{`#${end_point.id} ${end_point.name}`}</td>
                  </tr>
                  <tr>
                    <th scope="row">Loc ID</th>
                    <td>{start_point.loc_id}</td>
                    <td>{end_point.loc_id}</td>
                  </tr>
                </tbody>
              </table>
            </Tooltip>
          </Polyline>
        );
      }
      return null;
    })
  );
}
