import { useSelector } from 'react-redux';
import React, { Fragment } from 'react';
import * as plotterSelectors from '../plotter/plotterSelectors';
import { numberFormatter, priceFormatter } from '../helpers/formatters';
import { titleize } from '../utils/stringUtils';
import { selectMarkets } from '../scenarios/scenarioSlice';

export const LeafletNodeTooltip = (props) => {
  const nodes = useSelector(plotterSelectors.selectAllNodes);
  const nodeData = nodes[props.nodeId];
  const allPoints = useSelector(plotterSelectors.selectAllPoints);
  const nodePointsData = nodeData.points.map((pointId) => allPoints[pointId]);

  const markets = useSelector(selectMarkets);

  if (!nodePointsData || !nodePointsData[0]) {
    return null;
  }

  return (
    <>
      {nodePointsData?.[0].tsp_name} -{nodePointsData?.[0].zone_name}
      <ul className="list-group list-group-flush">
        {nodePointsData.map((pointData) => (
          <Fragment key={pointData.id}>
            <li className="list-group-item p-0">{pointData.name}</li>
            {markets
              .filter((market) => market.point == pointData.id)
              .map((market) => (
                <li key={`${market.description}_${market.price}`}>
                  {market.description && `${market.description}: `}{' '}
                  {priceFormatter.format(market.price)}
                </li>
              ))}
          </Fragment>
        ))}
      </ul>
    </>
  );
};

/**
 * @typedef {{
 *  node: import('../services/edgeApi').Node,
 *  points: import('../services/edgeApi').Point[],
 *  prices: Array<import('../scenarios/scenarioSlice'). | import('../scenarios/scenarioSlice').Trade>,
 *  constraints: import('../scenarios/scenarioSlice').PointConstraint[],
 *  operations: import('../scenarios/scenarioSlice').Operation[],
 *  isScenarioPage: boolean,
 *  isEditing: boolean,
 * }} NodeTooltipProps
 */

/**
 *
 * @param {NodeTooltipProps} props
 * @returns {React.FC<NodeTooltipProps>}
 */
export const NodeTooltip = ({
  node,
  points,
  prices,
  constraints,
  operations,
  isScenarioPage,
  isEditing,
}) => {
  return (
    <div className="bg-dark p-2 px-3 border-dark text-light rounded">
      <div style={{ fontSize: 12 }}>#{node?.id}</div>
      <div className="fs-6">
        {points[0]?.tsp_name}: {points[0]?.delivery_zone_name}
      </div>
      {points.map((point) => (
        <div key={point.id.toString()} className="fs-6">
          {point?.name}
        </div>
      ))}
      <div>
        {isScenarioPage && (
          <>
            {!isEditing && operations?.length > 0 && (
              <table className="table table-dark">
                <thead>
                  <tr>
                    <th className="text-center" colSpan="2">
                      Operations
                    </th>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {operations
                    .sort((a, b) => a.index - b.index)
                    .map(({ description, type }, index) => {
                      return (
                        <tr key={index}>
                          <td>{description}</td>
                          <td>{type}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            )}
            {constraints?.length > 0 && (
              <table className="table table-dark">
                <thead>
                  <tr>
                    <th className="text-center" colSpan="3">
                      Constraints
                    </th>
                  </tr>
                  <tr>
                    <th>Priority</th>
                    <th>Direction</th>
                    <th>OAC</th>
                  </tr>
                </thead>
                <tbody>
                  {constraints.map((constraint) => {
                    return (
                      <tr key={constraint.id}>
                        <td>{constraint.cuts_at_priority}</td>
                        <td>{titleize(constraint.flow_direction)}</td>
                        <td>{numberFormatter(constraint.max_volume)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </>
        )}
        {prices?.length > 0 && (
          <table className="table table-dark">
            <thead>
              <tr>
                <th className="text-center" colSpan="3">
                  Prices
                </th>
              </tr>
              <tr>
                <th>Buy/Sell</th>
                <th>Origin</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {prices.map((price) => {
                return (
                  <tr key={price.id}>
                    <td>{titleize(price.buy_sell || 'N/A')}</td>
                    <td>
                      {titleize(
                        price.price_source?.split('_').join(' ') || 'N/A',
                      )}
                    </td>
                    <td>{priceFormatter.format(price.price)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

// need to activate when a point is selected? Can also help with finding the point - probably have to use array of refs
