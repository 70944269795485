export function PointListItem({ point, onClick, active, children }) {
  // should we pass the pointId, or the whole point into this? or either one
  return (
    <li
      key={point.id}
      className={`list-group-item ${active ? 'active' : ''}`}
      onClick={onClick}
    >
      <div>
        <small>#{point.id}</small>
        <span className="float-end">{children}</span>
      </div>
      <div>
        {point.loc_id} -{point.name}
      </div>
      <div className="clearfix">
        <small className="float-start">
          {point.tsp_name} -{point.loc_zone_rec}
        </small>
        <small className="float-end">
          {point.loc_cnty},{point.loc_st}
        </small>
      </div>
      {point.up_dn_name && (
        <small>
          Connects to:
          {point.up_dn_name}
        </small>
      )}
      {!point.node && <div className="float-end text-danger">NOT MAPPED</div>}
    </li>
  );
}
