import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLazyGetSegmentConstraintsQuery } from '../services/edgeApi';
import { numberFormatter } from '../helpers/formatters';
import { EditableTable } from '../helpers/EditableTable';
import { DateSelector } from '../helpers/DateSelector';
import {
  scenarioActions,
  segmentConstraintKeyExtractor,
  selectSegmentConstraints,
} from '../scenarios/scenarioSlice';
import * as plotterSelectors from '../plotter/plotterSelectors';
import { constraintToString, toIsoDate } from '../utils/stringUtils';
import { colors } from '../utils/colors';
import { AnnotationWarningIcon, ZoomInIcon } from '@iconicicons/react';
import { UncontrolledTooltip } from 'reactstrap';
import { usePanMap } from '../plotter/usePanMap';
import { BelowMapContext } from '../layouts/BelowMapContext';
import { EditFeedbackSegmentInput } from '../helpers/EditFeedbackInput';
import toast from 'react-hot-toast';

/**
 *
 * @param {[number, number]} a
 * @param {[number, number]} b
 * @returns {[number, number]}
 */
const midPoint = (a, b) => [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2];

/**
 *
 * @param {{
 *  editedRows: string[],
 *  onClickEditionWarning: (constraint: import('../scenarios/scenarioSlice').SegmentConstraint) => void,
 *  isEditing: boolean,
 *  allEdges: {[key: number]: import('../services/edgeApi').Edge},
 *  allNodes: {[key: number]: import('../services/edgeApi').Node},
 *  allPoints: {[key: number]: import('../services/edgeApi').Point},
 *  onLocateEdge: (edge: Edge) => void,
 * }} params
 * @return {import('../helpers/EditableTable').EditableTableColumn<import('../scenarios/scenarioSlice').SegmentConstraint>[]}
 */
const createColumns = ({
  editedRows,
  onClickEditionWarning,
  isEditing,
  allEdges,
  allNodes,
  allPoints,
  onLocateEdge,
}) => [
  {
    title: 'Segment',
    allowFiltering: true,
    key: 'edge',
    type: 'text',
    placeholder: 'Segment',
    default: '',
    isEditable: false,
    informedOnCreation: true,
    width: 0.29,
    displayFunction: (value) => {
      const edge = allEdges[value];
      const sourceNode = allNodes[edge?.start_node];
      const targetNode = allNodes[edge?.end_node];
      const source = allPoints[sourceNode?.points[0]];
      const target = allPoints[targetNode?.points[0]];
      return `#${value} From ${source?.name} to ${target?.name}`;
    },
    renderInput: EditFeedbackSegmentInput,
    renderCell: ({ data }) => {
      const edge = allEdges[data.edge];
      const sourceNode = allNodes[edge?.start_node];
      const targetNode = allNodes[edge?.end_node];
      const source = allPoints[sourceNode?.points[0]];
      const target = allPoints[targetNode?.points[0]];
      const edgeName = constraintToString(source, target, data);

      return (
        <>
          <button
            className="btn btn-light me-2"
            disabled={!edge}
            onClick={() =>
              onLocateEdge(midPoint(edge?.positions[0], edge?.positions[1]))
            }
          >
            <ZoomInIcon />
          </button>
          <strong className="me-2">
            #{data.edge}
            {isEditing &&
              editedRows.includes(segmentConstraintKeyExtractor(data)) && (
                <>
                  <span
                    id={`segment-constraint-${data.edge}-edition-warning`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onClickEditionWarning(data)}
                  >
                    <AnnotationWarningIcon color={colors.matteYellow} />
                  </span>
                  <UncontrolledTooltip
                    target={`segment-constraint-${data.edge}-edition-warning`}
                  >
                    This constraint has been manually edited and some of its
                    data will not be updated. Click to revert this setting.
                  </UncontrolledTooltip>
                </>
              )}
          </strong>{' '}
          {Object.values(allEdges).length > 0 &&
          Object.values(allNodes).length > 0 &&
          Object.values(allPoints).length > 0
            ? edgeName
            : 'Loading edges...'}
        </>
      );
    },
  },
  {
    title: 'TSP',
    allowFiltering: true,
    key: 'pipeline',
    type: 'text',
    placeholder: 'TSP',
    isEditable: false,
    default: '',
    width: 0.06,
  },
  {
    title: 'Direction',
    allowFiltering: true,
    key: 'flow_direction',
    type: 'select',
    placeholder: 'Select Direction',
    default: 'placeholder',
    selectOptions: [
      { value: 'BACKHAUL', label: 'Backhaul' },
      { value: 'FORWARDHAUL', label: 'Forwardhaul' },
    ],
    width: 0.1,
    // renderCell: ({ data }) => {
    //   if (data.flow_direction === 'FORWARDHAUL') {
    //     return <ArrowRightIcon color={colors.matteGreen} />;
    //   } else if (data.flow_direction === 'BACKHAUL') {
    //     return <ArrowLeftIcon color={colors.matteRed} />;
    //   }
    //   return null;
    // },
  },
  {
    title: 'Description',
    allowFiltering: true,
    key: 'description',
    type: 'text',
    placeholder: 'Description',
    default: '',
    width: 0.18,
    renderCell: ({ data, value }) => {
      const id = `segment-constraint-${data.id}-description`;
      return (
        <>
          <span id={id}>{value}</span>
          <UncontrolledTooltip target={id}>{value}</UncontrolledTooltip>
        </>
      );
    },
  },
  {
    title: 'Constraint Factor (0-1)',
    key: 'constraint_factor',
    type: 'number',
    placeholder: 'Constraint Factor',
    default: 0,
    min: 0,
    max: 1,
    width: 0.09,
    setFunction: (value) => Math.min(Math.max(Number(value), 0), 1),
  },
  {
    title: 'Max Volume',
    key: 'max_volume',
    type: 'number',
    placeholder: 'Max Volume',
    default: 1000000,
    width: 0.1,
    displayFunction: numberFormatter,
  },
  {
    title: 'Cuts at Priority',
    allowFiltering: true,
    key: 'cuts_at_priority',
    type: 'select',
    placeholder: 'Select Priority',
    default: 'placeholder',
    selectOptions: [
      { value: 'PIP', label: 'PIP' },
      { value: 'SIP_PS', label: 'SIP_PS' },
      { value: 'SIP_SP', label: 'SIP_SP' },
      { value: 'SIP_SS', label: 'SIP_SS' },
      { value: 'SOP', label: 'SOP' },
      { value: 'IT', label: 'IT' },
    ],
    width: 0.08,
  },
];

/**
 * @typedef {{contextKey: string}} SegmentConstraintsProps
 * @param {SegmentConstraintsProps} props
 * @returns {React.FC<SegmentConstraintsProps>}
 */
export const SegmentConstraints = ({ contextKey }) => {
  const componentType = 'segment_constraints';
  const dispatch = useDispatch();
  const panMap = usePanMap();

  const belowMapContext = useContext(BelowMapContext);
  const fullScreen = belowMapContext?.fullScreen || false;
  const visible = belowMapContext?.activeTab === contextKey;
  const _segmentConstraints = useSelector(selectSegmentConstraints);

  const flowDate = useSelector((state) => state.scenario.data.flow_date);

  const allEdges = useSelector(plotterSelectors.selectAllEdges);
  const allNodes = useSelector(plotterSelectors.selectAllNodes);
  const allPoints = useSelector(plotterSelectors.selectAllPoints);

  /**
   * @type {import('../scenarios/scenarioSlice').ScenarioComponentSettingsAction<"segment_constraints">[]}
   */
  const segmentConstraintActions = useSelector(
    (state) => state.scenario.data.settings.segment_constraints.actions,
  );

  /** @type {boolean} */
  const isEditing = useSelector((state) => state.scenario.isEditing);

  /**
   * @type {import('../scenarios/scenarioSlice').SegmentConstraint[]}
   */
  const ignoredConstraints = useMemo(
    () =>
      segmentConstraintActions
        .filter((action) => action.type === 'REMOVE')
        .map((action) => action.data) || [],
    [segmentConstraintActions],
  );

  /**
   * @type {string[]}
   */
  const updatedConstraints = useMemo(
    () =>
      segmentConstraintActions
        ?.filter((action) => action.type === 'UPDATE')
        .map((action) => segmentConstraintKeyExtractor(action.data)) || [],
    [segmentConstraintActions],
  );

  const segmentConstraints = useMemo(() => {
    return _segmentConstraints.map((constraint) => {
      const edge = allEdges[constraint.edge];
      return {
        ...constraint,
        pipeline: edge?.tsp_short_name || edge?.tsp_name || '',
      };
    });
  }, [_segmentConstraints]);

  const onClickEditionWarning = (constraint) => {
    dispatch(
      scenarioActions.revertUpdateSettings({
        componentType,
        component: constraint,
      }),
    );
  };

  const columns = useMemo(
    () =>
      createColumns({
        editedRows: updatedConstraints,
        onClickEditionWarning,
        isEditing,
        allEdges,
        allNodes,
        allPoints,
        onLocateEdge: (edge) => {
          panMap(edge, 9);
        },
      }),
    [updatedConstraints.join(','), isEditing, allEdges, allNodes, allPoints],
  );

  const [
    segmentConstraintsTrigger,
    { isFetching: segmentConstraintsFetching },
  ] = useLazyGetSegmentConstraintsQuery();

  const [constraintDate, setConstraintDate] = useState(new Date(flowDate));
  const [isAdding, setIsAdding] = useState(false);
  const [showIgnored, setShowIgnored] = useState(false);

  useEffect(() => {
    setConstraintDate(new Date(flowDate));
  }, [flowDate]);

  const saveBulkEdit = (selection, updateData) => {
    dispatch(
      scenarioActions.bulkUpdate({
        componentType,
        componentIds: selection,
        data: updateData,
      }),
    );
  };

  const saveItem = (original, edited) => {
    const source = {
      source_type: original?.source_type || 'USER_DEFINED',
      source_id: original?.source_id || uuid(),
    };

    if (!original?.id) {
      setIsAdding(false);
      const point = allPoints[edited.edge];
      if (!point) {
        toast.error('Edge not found');
      }
      const node = allNodes[point.node];
      const edges =
        edited.flow_direction === 'FORWARDHAUL'
          ? node.starts_edges ?? []
          : node.ends_edges ?? [];

      edges.forEach((edge) => {
        dispatch(
          scenarioActions.updateComponent({
            componentType,
            component: {
              ...source,
              ...edited,
              edge,
            },
          }),
        );
      });

      return;
    }

    dispatch(
      scenarioActions.updateComponent({
        componentType,
        component: {
          ...source,
          ...edited,
        },
      }),
    );
  };

  const overwriteSegmentConstraints = async () => {
    try {
      const result = await segmentConstraintsTrigger({
        source: 'PLATTS',
        date: toIsoDate(constraintDate),
      }).unwrap(); // TODO: Update only data if is the same date
      dispatch(
        scenarioActions.overwrite({
          componentType,
          components: result,
          applySettings: true,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const overwriteConstraints = async () => {
    overwriteSegmentConstraints();
  };

  const removeItem = (id) => {
    dispatch(
      scenarioActions.removeComponent({ componentType, componentId: id }),
    );
  };

  /**
   *
   * @param {import('../scenarios/scenarioSlice').SegmentConstraint} constraint
   */
  const restoreItem = (constraint) => {
    dispatch(
      scenarioActions.restoreRemovedComponent({
        componentType,
        component: constraint,
      }),
    );
  };

  const bulkRemove = (ids) => {
    dispatch(scenarioActions.bulkDelete({ componentType, componentIds: ids }));
  };

  const ignoredConstraintsRows = useMemo(
    () =>
      ignoredConstraints.map((constraint) => (
        <tr
          style={{ borderLeft: `5px solid ${colors.matteRed}` }}
          className="table-danger"
          key={`ignored-segment-constraint-${constraint.id}`}
        >
          <td></td>
          <td>{constraint.edge}</td>
          <td>{constraint.flow_direction}</td>
          <td
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: 200,
            }}
          >
            {constraint.description}
          </td>
          <td>
            {Number((Number(constraint.constraint_factor) * 100).toFixed(0))}
          </td>
          <td>{constraint.max_volume}</td>
          <td>{constraint.cuts_at_priority}</td>
          <td>
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => restoreItem(constraint)}
            >
              Restore Constraint
            </button>
          </td>
        </tr>
      )),
    [ignoredConstraints.map((c) => c.id).join(',')],
  );

  const belowHeader = isEditing && ignoredConstraints.length > 0 && (
    <>
      <tr>
        <td className="position-relative" colSpan={columns.length + 2}>
          <div className="position-relative m-3">
            <div className="progress" style={{ height: 1 }}></div>
            <button
              type="button"
              className="position-absolute top-0 start-50 translate-middle btn btn-sm btn-secondary rounded-pill"
              style={{ height: '2rem' }}
              onClick={() => setShowIgnored((p) => !p)}
            >
              {showIgnored ? 'Hide' : 'Show'} {ignoredConstraints.length}{' '}
              ignored constraint
              {ignoredConstraints.length > 1 && 's'}
            </button>
            {showIgnored ? (
              <button
                type="button"
                className="position-absolute top-0 end-0 translate-middle btn btn-sm btn-danger rounded-pill"
                style={{ height: '2rem' }}
                onClick={() => {
                  ignoredConstraints.map((item) => restoreItem(item));
                }}
              >
                Restore All Constraints
              </button>
            ) : (
              ''
            )}
          </div>
        </td>
      </tr>
      {showIgnored && ignoredConstraintsRows}
    </>
  );

  /**
   *
   * @param {import('../scenarios/scenarioSlice').SegmentConstraint} item
   * @returns {React.CSSProperties}
   */
  const getRowStyle = (item) => {
    if (item.source_type === 'USER_DEFINED') {
      return { borderLeft: `5px solid ${colors.matteGreen}` };
    }
    if (updatedConstraints.includes(segmentConstraintKeyExtractor(item))) {
      return { borderLeft: `5px solid ${colors.matteYellow}` };
    }
    return {};
  };

  return (
    <>
      {isEditing && (
        <>
          <div className="row justify-content-between m-3">
            <div className="col-6 col-md-8 col-sm-12">
              <DateSelector
                value={toIsoDate(constraintDate)}
                onChange={(evt) => setConstraintDate(evt.target.valueAsDate)}
                isLoading={segmentConstraintsFetching}
                onConfirm={overwriteConstraints}
                buttonText="Get Constraints"
              />
            </div>
            <div className="col-auto">
              <button
                onClick={() => setIsAdding((prev) => !prev)}
                className="btn btn-success"
              >
                {isAdding ? 'Cancel' : 'Add Segment Constraint'}
              </button>
            </div>
          </div>
        </>
      )}
      <EditableTable
        columns={columns}
        belowHeader={belowHeader}
        removedData={ignoredConstraints}
        data={segmentConstraints}
        keyExtractor={segmentConstraintKeyExtractor}
        isEditing={isEditing}
        isEditable={isEditing}
        isAdding={isAdding}
        getRowStyle={getRowStyle}
        onBulkRemove={bulkRemove}
        onSaveBulkEdit={saveBulkEdit}
        onSaveItem={saveItem}
        onDeleteItem={removeItem}
        onCancelCreation={() => setIsAdding(false)}
        emptyText="No Segment Constraints"
        virtualized
        visible={visible}
        fullScreen={fullScreen}
      />
    </>
  );
};
