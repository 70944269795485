import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import toast from 'react-hot-toast';
import {
  plotterActions,
  fetchMapData,
  createEdge,
  createUpdateNode,
  deleteNode,
  deleteEdge,
  checkPointMatch,
} from '../plotter/plotterSlice';
import * as plotterSelectors from '../plotter/plotterSelectors';
import {
  useCreateUpdatePointMutation,
  useDeletePointMutation,
  useGetTSPsQuery,
  useGetZonesQuery,
} from '../services/edgeApi';

export function Point(props) {
  // TODO: put this selector in redux file
  // console.log('nodeId in point: ' + props.nodeId)
  const pointData = useSelector(plotterSelectors.selectAllPoints)[props.id];
  // console.log(pointData)
  // let nodeData = useSelector(plotterSelectors.selectAllNodes)[props.nodeId]
  const editPointId = useSelector((state) => state.plotter.editPointId);

  const dispatch = useDispatch();
  const [deletePoint, mutationResult] = useDeletePointMutation();
  // // if it's a new point, start out as isEditing
  const [isEditing, setIsEditing] = useState(!pointData || pointData?.isLocal);
  const handleDelete = () => {
    if (window.confirm('Are you sure you wish to delete this?')) {
      deletePoint(props.id);
    }
  };
  // const isEditing = props.id === editPointId

  if (isEditing) {
    return (
      <EditPoint
        pointData={pointData}
        nodeId={props.nodeId}
        setIsEditing={setIsEditing}
      />
    );
  }
  if (!pointData) {
    return <></>;
  }
  return (
    <>
      <hr />
      <div>{pointData.name}</div>
      <div>{pointData.loc_id}</div>

      {props.detailed && (
        <>
          <div className="row">
            <div className="col-sm-4">TSP Name:</div>
            <div className="col-sm-8">{pointData.tsp_name}</div>
          </div>
          <div className="row">
            <div className="col-sm-4">Segment:</div>
            <div className="col-sm-8">{pointData.seg_nbr}</div>
          </div>
          <div className="row">
            <div className="col-sm-4">Rec Zone:</div>
            <div className="col-sm-8">{pointData.loc_zone_rec}</div>
          </div>
          <div className="row">
            <div className="col-sm-4">Del Zone:</div>
            <div className="col-sm-8">{pointData.loc_zone_del}</div>
          </div>
          <div className="row">
            <div className="col-sm-4">Needs Review:</div>
            <div className="col-sm-8">
              {pointData.needs_review ? 'Yes' : 'No'}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">Review Notes:</div>
            <div className="col-sm-8">{pointData.review_notes}</div>
          </div>
        </>
      )}
      {props.isEditable ? (
        <Button
          block
          color="warning"
          outline
          onClick={() => setIsEditing(true)}
        >
          Edit Point
        </Button>
      ) : (
        ''
      )}
      {false && (
        <Button color="danger" block onClick={handleDelete}>
          Delete Point
        </Button>
      )}
    </>
  );
}

function EditPoint(props) {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { pointData } = props;

  const [pointState, setPointState] = useState({
    ...pointData,
    node: props.nodeId,
  });

  const {
    data: tsps,
    isLoading: tspsLoading,
    isError: tspLoadError,
  } = useGetTSPsQuery();
  const tspOptions = tsps
    ? Object.keys(tsps).map((tspId) => ({
        value: tspId,
        label: tsps[tspId].name,
      }))
    : [{ value: pointData?.tsp, label: pointData?.tsp }];
  const {
    data: zones,
    isLoading: zonesLoading,
    isError: zoneLoadError,
  } = useGetZonesQuery();
  // filter zones by selected tsp
  const zoneOptions = zones
    ? Object.keys(zones)
        .filter((zoneId) => zones[zoneId].tsp.id == pointState?.tsp)
        .map((zoneId) => ({
          value: zoneId,
          label: `${zones[zoneId].name} - ${zones[zoneId].tsp.name}`,
        }))
    : [];

  const [
    createUpdatePoint, // This is the mutation trigger
    mutationResult, // This is the destructured mutation result
  ] = useCreateUpdatePointMutation();

  const plotter = useSelector((state) => state.plotter);
  const allPoints = useSelector(plotterSelectors.selectAllPoints);

  const nodeData = useSelector(plotterSelectors.selectAllNodes)[props.nodeId];

  // const [pointState, setPointState] = useState({
  //   node: props.nodeId,
  //   id: pointData?.id,
  //   loc_id: pointData?.loc_id,
  //   loc_name: pointData?.loc_name,
  //   tsp_name: pointData?.tsp_name,
  //   needs_review: pointData?.needs_review,
  //   review_notes: pointData?.review_notes,
  //   complete: pointData?.complete,
  //   tsp: pointData?.tsp
  // })

  const [isMounted, setIsMounted] = useState(false);

  const handleSelectChange = (selectedOptions, actionMeta) => {
    let values = [];
    if (Array.isArray(selectedOptions)) {
      values = selectedOptions.map((option) => option.value);
    } else {
      values = selectedOptions.value;
    }
    // console.log(values)
    setPointState({ ...pointState, [actionMeta.name]: values });
  };

  let matchingButton;

  if (plotter.matchingPointId) {
    matchingButton = (
      <Button
        autoFocus
        onClick={() => {
          // setInputFocus();

          // dispatch(plotterActions.assignMatchingData());
          // dispatch(plotterActions.setEditPointId(plotter.matchingPointId))
          dispatch(
            plotterActions.assignPointToNode({
              point: allPoints[plotter.matchingPointId],
              node: nodeData,
            }),
          );
          inputRef.current.focus();
        }}
      >
        {allPoints[plotter.matchingPointId].name}
      </Button>
    );
  }

  // this should only be during edit mode, could be a good reason to move EditNode to its own thing
  useEffect(() => {
    if (props.isEditable) {
      dispatch(checkPointMatch());
    }
  }, []);

  useEffect(() => {
    // let isMounted = true;               // note mutable flag
    setIsMounted(true);
    // someAsyncOperation().then(data => {
    //   if (isMounted) setState(data);    // add conditional check
    // })
    return () => {
      // cleanup toggles value, if unmounted
      // isMounted = false
      setIsMounted(false);
    };
  }, []);

  const handleChange = (evt) => {
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    const { name } = evt.target;

    setPointState({ ...pointState, [name]: value });

    // dispatch(plotterActions.updateActiveNode({'name':name, 'value':value}))
  };

  const checkMatch = (evt) => {
    handleChange(evt);
    dispatch(checkPointMatch(evt.target.value));
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      // console.log("nodeData: " + nodeData)
      // console.log("pointState: " + pointState)
      await createUpdatePoint({
        point: pointState,
        node: nodeData,
      }).unwrap();
      props.setIsEditing(false);
      // save response to localdata?  Get more node data from response?
      toast.success('Point Created');
    } catch {
      toast.error('Something Went Wrong.  Please Try Again');
    } finally {
      if (isMounted) {
        props.setIsEditing(false);
      }
    }
  };

  const pointTypeOptions = [
    { value: 'INT', label: 'INT' },
    { value: 'PHANTOM', label: 'PHANTOM' },
    { value: 'LDC', label: 'LDC' },
    { value: 'WHD', label: 'WHD' },
    { value: 'END', label: 'END' },
    { value: 'OTH', label: 'OTH' },
    { value: 'GTH', label: 'GTH' },
    { value: 'STR', label: 'STR' },
    { value: 'PPT', label: 'PPT' },
    { value: 'LNG', label: 'LNG' },
    { value: null, label: 'blank' },
    { value: '', label: '' },
    { value: 'VIR', label: 'VIR' },
    { value: 'COM', label: 'COM' },
    { value: 'PLT', label: 'PLT' },
    { value: 'EGN', label: 'EGN' },
    { value: 'RNG', label: 'RNG' },
    { value: 'STA', label: 'STA' },
  ];

  return (
    <>
      <hr />
      <div>
        Editing Point:
        {pointState.id}
      </div>
      {matchingButton}

      <Form onSubmit={handleSubmit}>
        <FormGroup className="row">
          <Label htmlFor="loc_id" className="col-form-label col-sm-4">
            locId
          </Label>
          <div className="col-sm-8">
            <Input
              autoFocus
              autoComplete="off"
              innerRef={inputRef}
              className="form-control"
              type="text"
              name="loc_id"
              id="loc_id"
              value={pointState.loc_id || ''}
              onChange={handleChange}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label htmlFor="loc_name" className="col-form-label col-sm-4">
            locName
          </Label>
          <div className="col-sm-8">
            <Input
              type="text"
              name="loc_name"
              id="loc_name"
              value={pointState.loc_name || ''}
              onChange={handleChange}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-4">TSP</Label>
          <div className="col-sm-8">
            <Select
              // key={tspOptions.length}
              isLoading={tspsLoading}
              value={tspOptions.find(
                (option) => option.value == pointState.tsp,
              )}
              name="tsp"
              onChange={handleSelectChange}
              options={tspOptions}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-4">Receipt Zone</Label>
          <div className="col-sm-8">
            <Select
              // key={tspOptions.length}
              isLoading={zonesLoading}
              value={zoneOptions.find(
                (option) => option.value == pointState.receipt_zone,
              )}
              name="receipt_zone"
              onChange={handleSelectChange}
              options={zoneOptions}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-4">Delivery Zone</Label>
          <div className="col-sm-8">
            <Select
              // key={tspOptions.length}
              isLoading={zonesLoading}
              value={zoneOptions.find(
                (option) => option.value == pointState.delivery_zone,
              )}
              name="delivery_zone"
              onChange={handleSelectChange}
              options={zoneOptions}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label className="col-form-label col-sm-4">Point Type</Label>
          <div className="col-sm-8">
            <Select
              value={pointTypeOptions.find(
                (option) => option.value == pointState.loc_type_ind,
              )}
              name="loc_type_ind"
              onChange={handleSelectChange}
              options={pointTypeOptions}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label htmlFor="needs_review" className="col-form-label col-sm-4">
            Needs Review?
          </Label>
          <div className="col-sm-8">
            <Input
              type="checkbox"
              name="needs_review"
              id="needs_review"
              checked={pointState.needs_review}
              onChange={handleChange}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <Label htmlFor="review_notes" className="col-form-label col-sm-4">
            Review Notes
          </Label>
          <div className="col-sm-8">
            <Input
              type="textarea"
              name="review_notes"
              id="review_notes"
              value={pointState.review_notes || ''}
              onChange={handleChange}
            />
          </div>
        </FormGroup>

        <FormGroup className="row">
          <div className="col">
            <Input type="submit" name="submit" id="submit" value="Submit" />
          </div>
        </FormGroup>
      </Form>
      <button
        className="btn btn-outline-warning w-100"
        onClick={() => props.setIsEditing(false)}
      >
        Cancel Edit
      </button>
    </>
  );
}

// export function NodePoints(){
//
// }

export function Node(props) {
  // change
  const dispatch = useDispatch();
  const editNode = useSelector((state) => state.plotter.editNode);
  const nodeData = useSelector(plotterSelectors.selectAllNodes)[props.nodeId];

  const confirmDelete = () => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      dispatch(deleteNode(nodeData.id));
    }
  };

  return (
    <>
      <div>
        Node:
        {props.id}
      </div>
      {props.isEditable ? ( // hide these buttons if not applicable - could also do it on a user to user basis
        <>
          {nodeData.id !== 'new' ? ( // if id is 'new' the node hasn't been created in the db yet
            <div className="btn-group d-flex">
              {!editNode ? (
                <Button
                  className="w-100"
                  onClick={() => dispatch(plotterActions.setEditNode(true))}
                >
                  Edit Location
                </Button>
              ) : (
                <>
                  <Button
                    className="w-100"
                    onClick={() => dispatch(createUpdateNode())}
                  >
                    Save
                  </Button>
                  <Button onClick={() => dispatch(fetchMapData())}>
                    Cancel
                  </Button>
                </>
              )}
            </div>
          ) : null}
        </>
      ) : null}
      <div className="mb-3 mt-3">
        {nodeData.points?.map((point) => (
          <Point
            key={point}
            id={point}
            isEditable={props.isEditable}
            nodeId={props.id}
          />
        ))}
        {props.isEditable && nodeData.id !== 'new' ? (
          <Button
            block
            color="danger"
            className="mb-3 mt-3"
            onClick={() => confirmDelete()}
          >
            Delete Node
          </Button>
        ) : null}
      </div>
    </>
  );
}

function EdgePointDetail(props) {
  const points = useSelector((state) => state.plotter.points);
  const point = points[props.point];

  return (
    <>
      <div>
        loc
        {point.loc_id}
      </div>
      <div>{point.name}</div>
      <hr />
    </>
  );
}

export function Edge(props) {
  const dispatch = useDispatch();
  const activeEdge = useSelector((state) => state.plotter.activeEdge);
  const startNode = useSelector(plotterActions.selectStartNode);
  const endNode = useSelector(plotterActions.selectEndNode);
  const points = useSelector((state) => state.plotter.points);
  // const nodes = useSelector((state) => state.plotter.nodes)

  const [endPointLocId, setEndPointLocId] = useState(null);

  // const nodeDetails = (node) => (<>{node.id}{node.points.map((point) => <Point id={point} /> )}</>)
  const nodeDetails = (node) => (
    <>
      {node.id}
      {node.points.map((point) => (
        <div key={point}>{points[point].loc_id}</div>
      ))}
    </>
  );

  const setEndNode = () => {
    const pointId = Object.keys(points).filter(
      (key, index) => points[key].loc_id === endPointLocId,
    )[0];
    // const node = nodes[point.node]
    const point = points[pointId];
    dispatch(plotterActions.setEndNode(point.node));
  };

  const confirmDelete = () => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      dispatch(deleteEdge(activeEdge.id));
    }
  };

  return (
    <>
      <div>
        {activeEdge?.id ? `Edge: ${activeEdge.id}` : null}
        {props.isEditable && activeEdge?.id ? (
          <Button
            color="danger"
            className="float-end"
            onClick={() => confirmDelete()}
          >
            Delete Edge
          </Button>
        ) : null}
        {startNode && endNode && !activeEdge?.id ? (
          <Button
            color="success"
            className="float-end"
            autoFocus
            onClick={() => dispatch(createEdge(activeEdge))}
          >
            Save Edge
          </Button>
        ) : null}
      </div>
      <hr />
      <div>
        Start Node:
        {startNode ? nodeDetails(startNode) : 'Select Start Node'}
      </div>
      <hr />
      <div>
        End Node:
        {endNode ? nodeDetails(endNode) : 'Select End Node'}
      </div>
      {props.isEditable ? (
        <Form
          onSubmit={(evt) => {
            evt.preventDefault();
            setEndNode();
          }}
        >
          <Input
            onChange={(evt) => setEndPointLocId(evt.target.value)}
            name="endPointLocId"
            value={endPointLocId || ''}
          />
          <Input type="submit" value="Set End Node" />
        </Form>
      ) : null}
    </>
  );
}
