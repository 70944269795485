import { useSelector } from 'react-redux';
import * as plotterSelectors from '../plotter/plotterSelectors';
import { PointToCapacityComponent } from '../capacityComponents/PointToCapacityComponent';
import { PointToPriceComponent } from '../priceComponents/PointToPriceComponent';
import { PopupMarkets } from '../markets/PopupMarkets';
import { PopupTrades } from '../trades/PopupTrades';
import { PopupPointConstraints } from '../pointConstraints/PopupPointConstraint';
import React, { useState } from 'react';

/**
 *
 * @param {{
 *  node?: import('../services/edgeApi').Node,
 *  onClose?: () => void,
 * }} props
 * @returns
 */
export const NodePopup = ({ node }) => {
  const allPoints = useSelector(plotterSelectors.selectAllPoints);
  const nodePointsData = node?.points.map((pointId) => allPoints[pointId]);
  const [openPoint, setOpenPoint] = useState(null);

  // this prevents nodes without points from throwing an error - like during map builder mode when creating new nodes that don't yet have a point
  if (!node || !nodePointsData?.[0]) {
    return null;
  }

  return (
    <div
      className="flex-column w-100 overflow-auto"
      style={{
        maxHeight: '60vh',
        minWidth: 350,
        maxWidth: 600,
        overflowY: 'auto',
        paddingRight: -8,
      }}
    >
      <h4
        className="text-center bg-light pb-2"
        style={{
          position: 'sticky',
          top: -8,
          zIndex: 10,
        }}
      >
        {nodePointsData?.[0].tsp_name} #{node.id}
      </h4>
      <h6 className="text-center">{'Click a location to open details'}</h6>
      <ul className="list-group list-group-flush">
        {nodePointsData.map((pointData) => (
          <li
            key={pointData.id}
            className="list-group-item bg-light p-0 ps-2 mt-4 rounded-start border-5 border-primary border-bottom-0 border-top-0 border-end-0"
          >
            <div className="d-flex">
              <h6
                key={pointData.id}
                className="fw-bold float-start flex-fill align-text-top lh-lg me-2"
                role="button"
                onClick={() => {
                  setOpenPoint(
                    pointData.id === openPoint ? null : pointData.id,
                  );
                }}
              >
                {pointData.name} - {pointData.zone_name}
              </h6>
            </div>
            <div style={pointData.id === openPoint ? {} : { display: 'none' }}>
              <PopupMarkets pointId={pointData.id} />
              <PopupTrades pointId={pointData.id} />
              <PopupPointConstraints pointId={pointData.id} />
              {/*
                  this name sucks but don't want to call it PointCapacityComponent becuase we have "point capacity"
                  TODO: These two components' structure is very similar. Can they be combined?
                */}
              <PointToCapacityComponent pointId={pointData.id} />
              <PointToPriceComponent pointId={pointData.id} />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

// could show simple stuff on hover, more complex stuff on click
// need to activate when a point is selected? Can also help with finding the point
// add / view market data when it exists
// at what point does this all start to bog down?
