import { useSelector } from 'react-redux';
import {
  selectAllEdges,
  selectAllPoints,
  selectFilteredEdgeNodes,
} from '../plotter/plotterSelectors';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON.js';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { WebGLLayer } from './webglLayer';
import { mapContext } from './EdgeMap';
import { fromLonLat } from 'ol/proj';
import {
  selectFilteredIOCContracts,
  selectIOCVisibility,
} from '../ioc/iocSlice';
import { IOCSegmentModal } from '../ioc/IOCSegmentModal';

const getPathStyle = () => [
  {
    filter: ['==', ['get', 'priority'], 1],
    'stroke-width': 12,
    'stroke-color': 'hsl(200,100%,75%)',
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
    'stroke-opacity': 0.8,
  },
  {
    filter: ['==', ['get', 'priority'], 0],
    'stroke-width': 12,
    'stroke-color': 'hsl(60,100%,75%)',
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
    'stroke-opacity': 0.8,
  },
];

export const MapIOCPaths = ({ zIndex }) => {
  const { map, registerClickListener, unregisterClickListener } =
    useContext(mapContext);
  const nodes = useSelector(selectFilteredEdgeNodes);
  const allEdges = useSelector(selectAllEdges);
  const allPoints = useSelector(selectAllPoints);
  const iocContracts = useSelector(selectFilteredIOCContracts);
  const visibility = useSelector(selectIOCVisibility);

  /**
   * @type {import('../types/types').State<string | null>}
   */
  const [modalKey, setModalKey] = useState(null);

  const isIocPage = useMemo(
    () => window.location.pathname.includes('/index-of-customers'),
    [window.location.pathname],
  );

  /** @type {import('./EdgeMap').MapEventListener} */
  const clickListener = useCallback(
    (feature, _) => {
      if (!isIocPage) return;
      if (feature?.getProperties()?.key?.startsWith('ioc-')) {
        const lookup = feature.getProperties()?.id;
        if (feature?.getProperties()?.type === 'edge') {
          setModalKey(lookup);
        }
      }

      if (feature?.getProperties()?.key?.startsWith('edge-')) {
        const edgeId = feature.getProperties()?.id;
        const edge = allEdges[edgeId];
        const lookup = `${edge.start_node}-${edge.end_node}`;
        setModalKey(lookup);
      }
    },
    [modalKey, allEdges, isIocPage],
  );

  const zoneEdgeLookup = useMemo(
    /**
     *
     * @returns {Object<string, import('../services/edgeApi').Edge[]>}
     */
    () => {
      return Object.values(allEdges).reduce((acc, edge) => {
        const edgeZone = edge.zone_id?.toString();
        if (!acc[edgeZone]) acc[edgeZone] = [];
        acc[edgeZone].push(edge);
        return acc;
      }, {});
    },
    [allEdges],
  );

  /**
   * @typedef {[import('../services/edgeApi').Node, import('../services/edgeApi').Node]} PathSegment
   */

  const primarySet = useMemo(() => {
    /**
     * @type {Set<PathSegment>}
     */
    const set = new Set();
    iocContracts
      .filter((contract) => visibility[contract.id])
      .forEach((contract) => {
        contract.path_node_ids.forEach(([from, to]) => {
          const fromNode = nodes[from];
          const toNode = nodes[to];
          if (fromNode && toNode) set.add([fromNode, toNode]);
        });
      });
    return set;
  }, [iocContracts, visibility, nodes]);

  const secondarySet = useMemo(() => {
    /**
     * @type {Set<string>}
     */
    const set = new Set();
    iocContracts
      .filter((contract) => visibility[contract.id])
      .forEach((contract) => {
        contract.receipt_points.forEach((point_id) => {
          const zone = allPoints[point_id]?.receipt_zone?.toString();
          if (!zone) return;
          set.add(zone);
        });
        contract.delivery_points.forEach((point_id) => {
          const zone = allPoints[point_id]?.delivery_zone?.toString();
          if (!zone) return;
          set.add(zone);
        });
      });

    /** @type {PathSegment[]} */
    const segments = Array.from(set).flatMap((zone) => {
      const zoneEdges = zoneEdgeLookup[zone];
      if (!zoneEdges) return [];
      return zoneEdges
        .map((edge) => {
          const from = nodes[edge.start_node];
          const to = nodes[edge.end_node];
          return [from, to];
        })
        .filter(([from, to]) => from && to);
    });
    return segments;
  }, [iocContracts, visibility, allPoints, zoneEdgeLookup, nodes]);

  const iocPathsLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features: Array.from(primarySet)
          .map(([from, to]) => {
            return {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [from, to].map((node) =>
                  fromLonLat([node.lng, node.lat]),
                ),
              },
              properties: {
                id: `${from.id}-${to.id}`,
                key: `ioc-${from.id}-${to.id}`,
                type: 'edge',
                priority: 0,
              },
            };
          })
          .concat(
            secondarySet.map(([from, to]) => {
              return {
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: [from, to].map((node) =>
                    fromLonLat([node.lng, node.lat]),
                  ),
                },
                properties: {
                  id: `${from.id}-${to.id}`,
                  key: `ioc-${from.id}-${to.id}`,
                  type: 'edge',
                  priority: 1,
                },
              };
            }),
          ),
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getPathStyle(),
      zIndex,
    });
  }, [primarySet, secondarySet]);

  useEffect(() => {
    if (!map || !iocPathsLayer) return;
    map.addLayer(iocPathsLayer);
    return () => {
      map.removeLayer(iocPathsLayer);
    };
  }, [map, iocPathsLayer]);

  useEffect(() => {
    if (!map) return;
    registerClickListener(clickListener);
    return () => {
      unregisterClickListener(clickListener);
    };
  }, [map, clickListener]);

  const modalInfo = useMemo(() => {
    if (!modalKey) return {};

    const [from, to] = modalKey.split('-');
    const fromNode = nodes[from];
    const toNode = nodes[to];
    const receipt_zone = fromNode.points.find(
      (point) => allPoints[point]?.receipt_zone,
    )?.receipt_zone;
    const delivery_zone = toNode.points.find(
      (point) => allPoints[point]?.delivery_zone,
    )?.delivery_zone;
    const zone = receipt_zone ?? delivery_zone;
    const fromPoint = allPoints[fromNode?.points[0]];
    const toPoint = allPoints[toNode?.points[0]];
    const tsp = fromPoint?.tsp_name ?? toPoint?.tsp_name;
    return { fromPoint, toPoint, tsp, zone };
  }, [modalKey, nodes, allPoints]);

  return (
    <>
      <IOCSegmentModal
        key={modalKey}
        segmentKey={modalKey}
        onClose={() => setModalKey(null)}
        tspName={modalInfo.tsp}
        fromPoint={modalInfo.fromPoint}
        toPoint={modalInfo.toPoint}
        zone={modalInfo.zone}
      />
    </>
  );
};
